import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BackgroundImage extends Component {
  componentWillUnmount() {
    window.$('.headerSlider.slick-initialized').slick('destroy');
  }

  render = () => {
    const { url } = this.props;
    return (
      <div className="item">
        <div className="sliderMetAfbeelding Aligner" style={{ backgroundImage: `url(${url})` }}>
          <div className="Aligner-item sliderBinnen" />
        </div>
      </div>
    );
  };
}

export default BackgroundImage;

BackgroundImage.defaultProps = {
};

BackgroundImage.propTypes = {
  url: PropTypes.string,
};
