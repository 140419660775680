import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from '../BackgroundImage';
import './BackgroundImageList.css';

const BackgroundImageList = ({ slides }) => {
  const backgroundImageList = (slides || []).map((slide) => (
    <BackgroundImage
      key={slide.id}
      url={slide.url}
    />
  ));

  setTimeout(() => {
    if (window.$('.headerSlider.slick-initialized').length > 0) {
      window.$('.headerSlider.slick-initialized').slick('refresh');
      return;
    }

    window.$('.headerSlider').slick({
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      arrows: true,
      prevArrow: "<button type='button' class='slick-arrow slick-prev pull-left' ><i class='far fa-chevron-left'></i></button>",
      nextArrow: "<button type='button' class='slick-arrow slick-next pull-right' ><i class='far fa-chevron-right'></i></button>",
    });
  }, 0);

  return (
    <div className="sliderHeader headerSlider">
      {backgroundImageList}
    </div>
  );
};

export default BackgroundImageList;

BackgroundImageList.defaultProps = {
};

BackgroundImageList.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object),
};
