import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MainMenuList = ({ navigationNodes }) => {
  const nodeList = (navigationNodes || []).map((node, index) => {
    const keyHelper = `{_.random(0, 1000, false)}${index}`;
    if (node.children.length) {
      const childrenList = (node.children).map((child) => (
        <li className="nav-item" key={child.element.uid}>
          <Link className="nav-link" to={`/${child.element.uri}`}>
            {child.title}
          </Link>
        </li>
      ));

      return (
        <li className="nav-item btn-group" key={_.get(node, 'element.uid', `${node.title}${keyHelper}`)}>
          <Link className="nav-link " to={`/${node.element.uri}`}>
            {node.title}
          </Link>
          <button
            type="button"
            className="btn btn-link"
            id={`dropdown${node.element.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-reference="parent"
            style={{ background: 'none', border: 'none', marginLeft: '-8px' }}
          >
            <i className="far fa-chevron-down" />
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu" aria-labelledby={`dropdown${node.element.id}`} style={{ width: '300px', maxWidth: '90%' }}>
            {childrenList}
          </ul>
        </li>
      );
    }

    return (
      <li className="nav-item" key={keyHelper}><Link className="nav-link " to={`/${node.element.uri}`}>{node.title}</Link></li>
    );
  });

  return (
    <nav className="navbar navbar-expand-md">
      <Link className="navbar-brand" to="/">
        <img src="themes/thema-scholtgroup/assets/images/xlogo%2c402x.png.pagespeed.ic.7VFJF_eEKV.png" className="img-fluid" alt="" />
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fal fa-bars" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="ml-auto">
          <div className="clearFix" />
          <ul className="navbar-nav ml-auto" aria-labelledby="">
            {nodeList}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default MainMenuList;

MainMenuList.defaultProps = {
  navigationNodes: [],
};

MainMenuList.propTypes = {
  navigationNodes: PropTypes.oneOfType([PropTypes.array]),
};
