import React from 'react';

const Map = () => (
  <div className="kaart">
    <iframe
      title="Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79779.92295952224!2d5.392471057576222!3d51.32726493167875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6d65f825545cf%3A0x455e80d67ed58428!2sScholt%20Group%20B.V.!5e0!3m2!1snl!2snl!4v1613027091563!5m2!1snl!2snl"
      width="100%"
      height="500"
      style={{ border: 0 }}
      allowFullScreen=""
      aria-hidden="false"
    />
  </div>
);

export default Map;

Map.defaultProps = {
};
