import axios from 'axios';
import NAVIGATION_FETCHED from './types';
import { GRAPHQL_QUERY_MENU } from '../models/query';

const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL;
const GRAPHQL_API_TOKEN = process.env.REACT_APP_GRAPHQL_API_TOKEN;

const fetchNavigation = () => {
  const params = new URLSearchParams();
  params.append('query', GRAPHQL_QUERY_MENU);

  const request = axios.post(
    `${GRAPHQL_API_URL}`,
    params,
    {
      dataType: 'json',
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${GRAPHQL_API_TOKEN}`,
      },
    },
  );
  return {
    type: NAVIGATION_FETCHED,
    payload: request,
  };
};

export default fetchNavigation;
