import _ from 'lodash';

import * as types from './types';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PAGE_FETCHED: {
      const page = _.get(action, 'payload.data.data.entry', false);

      if (page === false) {
        return state;
      }

      if (page === null) {
        window.location.replace('404.html');

        return state;
      }

      const newPage = {
        [page.uri]: page,
      };

      return (state === null) ? newPage : { ...state, ...newPage };
    }
    default: {
      return state;
    }
  }
};
