import axios from 'axios';
import _ from 'lodash';
import * as types from './types';

const CRAFT_API_URL = process.env.REACT_APP_CRAFT_API_URL;

const submitContact = (values) => {
  const params = new URLSearchParams();

  _.each(values, (item) => {
    params.append(item.name, item.value);
  });

  const request = axios.post(
    `${CRAFT_API_URL}`,
    params,
    {
      dataType: 'json',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
    },
  );

  return {
    type: types.CONTACT_CREATED,
    payload: request,
    meta: params,
  };
};

export {
  submitContact,
};
