import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text from '../Text';
import ImageSliderList from '../ImageSliderList';
import ImageTextCardList from '../ImageTextCardList';
import ParticipationList from '../../../containers/ParticipationList';
import Map from '../Map';
import ContactForm from '../../../containers/ContactForm';

const MatrixBlock = ({ blocks }) => {
  const blockList = (blocks || []).map((block, index) => {
    const typeName = _.get(block, '__typename', null);
    const keyIndex = `${typeName}-${index}-{_.random(0, 1000, false)}`;

    switch (typeName) {
      case 'blocks_text_BlockType':
        return (
          <Text
            body={block.text}
            key={keyIndex}
            logo={_.get(block, 'logo[0].url', null)}
            link={_.get(block, 'backLink[0].uri', null)}
          />
        );
      case 'blocks_slider_BlockType':
        return (
          <ImageSliderList
            slides={block.slide}
            key={keyIndex}
          />
        );
      case 'blocks_pageBlocks_BlockType':
        return (
          <ImageTextCardList
            cards={block.pages}
            key={keyIndex}
          />
        );
      case 'blocks_participations_BlockType':
        return (
          <ParticipationList
            categoryId={block.participationCategories[0].id}
            key={keyIndex}
          />
        );
      case 'blocks_map_BlockType':
        return (
          <Map key={keyIndex} />
        );
      case 'blocks_contactForm_BlockType':
        return (
          <ContactForm key={keyIndex} />
        );
      default:
        return null;
    }
  });

  return (
    <>
      {blockList}
    </>
  );
};

export default MatrixBlock;

MatrixBlock.defaultProps = {
};

MatrixBlock.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};
