import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submitContact } from '../../redux-modules/contact/actions';

class ContactForm extends Component {
  onFormSubmit = (event) => {
    const { submitContact: submitContactAction } = this.props;
    event.preventDefault();
    submitContactAction(window.$(event.target).serializeArray());
  };

  render = () => {
    const { contact } = this.props;

    if (contact.status === 'sent') {
      return (
        <div className="container">
          <div className="row">
            <div className="offset-sm-1 col-md-10">
              <div className="introTekst text-sm-center shadow-sm">
                <div className="contactFormulierContainer pt-5">
                  <h1>Bedankt voor het sturen van een bericht.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="offset-sm-1 col-md-10">
            <div className="introTekst text-sm-center shadow-sm">
              <div className="contactFormulierContainer pt-5">
                <form onSubmit={this.onFormSubmit}>
                  <div className="formulierContainer">
                    <div style={{ display: 'none' }}>
                      <input type="hidden" name="action" value="contact-form/send" />
                      <input type="text" name="industryName" />
                    </div>
                    <div className="form-group">
                      <input
                        required="required"
                        type="text"
                        id="Naam"
                        name="fromName"
                        placeholder="Naam"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        required="required"
                        id="Bedrijfsnaam"
                        name="message[Company]"
                        placeholder="Bedrijfsnaam"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        required="required"
                        id="Email"
                        name="fromEmail"
                        placeholder="Email"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        required="required"
                        id="Telefoonnummer"
                        name="message[Phone]"
                        placeholder="Telefoonnummer"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        id="Bericht"
                        name="message[body]"
                        placeholder="Uw bericht"
                        rows="8"
                        cols="80"
                        className="form-control"
                      />
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          id="simpleContactSubmitButton"
                          type="submit"
                          className="btn btn-primary float-sm-right"
                        >
                          Verzenden
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contact: state.contact,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { submitContact },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
