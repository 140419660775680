import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import BackgroundImageList from '../../components/blocks/BackgroundImageList/BackgroundImageList';
import MatrixBlock from '../../components/blocks/MatixBlock/MatrixBlock';
import fetchPage from '../../redux-modules/page/actions';

class PageContent extends Component {
  componentDidMount() {
    const {
      fetchPage: fetchPageAction,
      page,
      currentUrl,
    } = this.props;

    const currentPage = _.get(page, currentUrl, null);
    if (!currentPage) {
      fetchPageAction(currentUrl);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchPage: fetchPageAction,
      page,
      currentUrl,
    } = this.props;

    if (currentUrl === prevProps.currentUrl) {
      return;
    }

    const currentPage = _.get(page, currentUrl, null);
    if (!currentPage) {
      fetchPageAction(currentUrl);
    }
  }

  render = () => {
    const {
      page,
      currentUrl,
    } = this.props;

    const currentPage = _.get(page, [currentUrl], null);
    if (page === null) {
      return (
        <div style={{ minHeight: '1500px' }} />
      );
    }

    const seoBlock = currentPage === null ? '' : (
      <Helmet>
        <title>{currentPage.seo.title || 'Scholtgroup'}</title>
        <meta name="description" content={currentPage.seo.description || 'Scholtgroup'} />
      </Helmet>
    );

    return (
      <div>
        {seoBlock}
        <BackgroundImageList slides={_.get(currentPage, 'images', null)} />
        <MatrixBlock blocks={_.get(currentPage, 'blocks', null)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.page,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { fetchPage },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);

PageContent.propTypes = {
  fetchPage: PropTypes.func.isRequired,
  currentUrl: PropTypes.string.isRequired,
  page: PropTypes.oneOfType([PropTypes.object]),
};
