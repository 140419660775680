import axios from 'axios';
import * as types from './types';
import { GRAPHQL_QUERY_PARTICIPATION } from '../models/query';

const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL;
const GRAPHQL_API_TOKEN = process.env.REACT_APP_GRAPHQL_API_TOKEN;

const fetchParticipations = (categoryId) => {
  const params = new URLSearchParams();
  params.append('query', GRAPHQL_QUERY_PARTICIPATION);
  params.append('variables[categoryId]', parseInt(categoryId, 10));

  const request = axios.post(
    `${GRAPHQL_API_URL}`,
    params,
    {
      dataType: 'json',
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${GRAPHQL_API_TOKEN}`,
      },
    },
  );

  return {
    type: types.PARTICIPATION_FETCHED,
    payload: request,
    meta: {
      categoryId,
    },
  };
};

export default fetchParticipations;
