import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FooterMenuList = ({ navigationNodes }) => {
  const nodeList = (navigationNodes || []).map((node, index) => {
    const keyHelper = `{_.random(0, 1000, false)}${index}`;

    return (
      <li className="col-3 mb-2" key={keyHelper}><Link to={`/${node.element.uri}`}>{node.title}</Link></li>
    );
  });

  return (
    <nav className="col-12">
      <ul className="row list-unstyled mb-0" aria-labelledby="">
        {nodeList}
      </ul>
    </nav>
  );
};
export default FooterMenuList;

FooterMenuList.defaultProps = {
  navigationNodes: [],
};

FooterMenuList.propTypes = {
  navigationNodes: PropTypes.oneOfType([PropTypes.array]),
};
