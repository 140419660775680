import _ from 'lodash';

import * as types from './types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PARTICIPATION_FETCHED: {
      const participations = _.get(action, 'payload.data.data.entries', null);

      const { categoryId } = action.meta;
      const newParticipations = {
        [categoryId]: participations,
      };

      return (state === null) ? newParticipations : { ...state, ...newParticipations };
    }
    default: {
      return state;
    }
  }
};
