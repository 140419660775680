import React from 'react';
import FooterMenu from '../../containers/FooterMenu/FooterMenu';

const Footer = () => (
  <footer className="pt-4 pb-4">
    <div className="container">
      <div className="row beeldmerkRij">
        <div className="col-sm-12 text-sm-center">
          <img
            src="/themes/thema-scholtgroup/assets/images/xbeeldmerk,402x.png.pagespeed.ic.Q-4OBhSwzi.png"
            className="beeldmerk"
            alt="logo"
          />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-sm-7">
          <div className="row mb-5">
            <FooterMenu />
          </div>
          <div className="row mt-5 pt-5">
            <div className="col-sm-6 col-md-4">
              <img src="/themes/thema-scholtgroup/assets/images/xlogo-wit%2c402x.png.pagespeed.ic.ZVIDpV33iw.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <div className="offset-sm-2 col-sm-3">
          <p><strong>Contact</strong></p>
          <p>
            Postadres:
            <br />
            Postbus 175
            <br />
            5550 AD Valkenswaard (NL)
          </p>
          <p>
            <span>
              Bezoekadres:
              <br />
              Maastrichterweg 191
              <br />
              5556 VB Valkenswaard (NL)
            </span>
            <br />
            <span>
              E:
            </span>
            <a href="mailto:info@scholtgroup.com"><span> info@scholtgroup.com</span></a>
            <br />
            <span>T: +31 (0)40 303 26 25</span>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

Footer.defaultProps = {
};
