import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ImageSlider extends Component {
  componentWillUnmount() {
    window.$('.paginaSlider.slick-initialized').slick('destroy');
  }

  render = () => {
    const {
      image,
      title,
      button,
      logo,
    } = this.props;

    const hasButton = button.length !== 0;
    let buttonFinal = null;

    if (hasButton) {
      buttonFinal = button[0].type === 'verbb\\hyper\\links\\Url' || button[0].type === 'verbb\\hyper\\links\\Custom'
        ? (<a href={button[0].url} className="leesArtikel" target={button[0].target}>{button[0].text}</a>)
        : (<Link to={`/${button[0].element.uri}`} className="leesArtikel" target={button[0].target}>{button[0].text}</Link>);
    }

    const logoImage = logo !== null
      ? (
        <img
          src={logo}
          style={{ maxWidth: '140px', height: 'auto', display: 'inline-block' }}
          alt="Slider logo"
        />
      )
      : null;

    return (
      <div className="item">
        <div className="sliderMetAfbeelding Aligner" style={{ backgroundImage: `url(${image})` }}>
          <div className="Aligner-item sliderBinnen">
            <div className="text-sm-center mb-3">
              {logoImage}
            </div>
            {title !== null && (<h3>{title}</h3>)}
            {buttonFinal}
          </div>
        </div>
      </div>
    );
  };
}

export default ImageSlider;

ImageSlider.defaultProps = {
};

ImageSlider.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.object]),
  logo: PropTypes.string,
};
