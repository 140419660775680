import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import fetchParticipations from '../../redux-modules/participation/actions';
import ImageTextCardList from '../../components/blocks/ImageTextCardList';

class ParticipationList extends Component {
  componentDidMount() {
    const { fetchParticipations: fetchParticipationsAction, categoryId } = this.props;

    fetchParticipationsAction(categoryId);
  }

  render = () => {
    const { participations, categoryId } = this.props;
    return <ImageTextCardList cards={_.get(participations, categoryId, [])} />;
  }
}

const mapStateToProps = (state) => ({
  participations: state.participation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { fetchParticipations },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipationList);

ParticipationList.propTypes = {
  fetchParticipations: PropTypes.func.isRequired,
  participations: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
