import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import RegularPage from '../../pages/RegularPage';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/"
        component={RegularPage}
      />
      <Route
        component={RegularPage}
      />
    </Switch>
  </BrowserRouter>
);

export default App;
