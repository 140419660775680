import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ImageSlider from '../ImageSlider';

const ImageSliderList = ({ slides }) => {
  setTimeout(() => {
    if (window.$('.paginaSlider.slick-initialized').length > 0) {
      window.$('.paginaSlider.slick-initialized').slick('refresh');
      return;
    }

    window.$('.paginaSlider').slick({
      dots: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      arrows: true,
      prevArrow: "<button type='button' class='slick-arrow slick-prev pull-left' ><i class='far fa-chevron-left'></i></button>",
      nextArrow: "<button type='button' class='slick-arrow slick-next pull-right' ><i class='far fa-chevron-right'></i></button>",
    });
  }, 0);

  const imageSliderList = (slides || []).map((slide) => (
    <ImageSlider
      key={slide.uid}
      title={slide.slideTitle}
      image={slide.image[0].url}
      button={slide.button}
      logo={_.get(slide, 'logo[0].url', null)}
    />
  ));

  return (
    <div className="blauweRij">
      <div className="container">
        <div className="row">
          <div className="offset-sm-1 col-md-10">
            <div className="buitenSlider">
              <div className="sliderHeader paginaSlider">
                {imageSliderList}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSliderList;

ImageSliderList.defaultProps = {
  slides: [],
};

ImageSliderList.propTypes = {
  slides: PropTypes.oneOfType([PropTypes.array]),
};
