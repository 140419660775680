import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import fetchNavigation from '../../redux-modules/navigation/actions';
import MainMenuList from '../../components/MainMenuList';

class MainMenu extends Component {
  componentDidMount() {
    const { fetchNavigation: fetchNavigationAction } = this.props;

    fetchNavigationAction();
  }

  render = () => {
    const { navigations } = this.props;
    return <MainMenuList navigationNodes={_.get(navigations, 'topNavigation', [])} />;
  }
}

const mapStateToProps = (state) => ({
  navigations: state.navigation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { fetchNavigation },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);

MainMenu.propTypes = {
  fetchNavigation: PropTypes.func.isRequired,
  navigations: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};
