import _ from 'lodash';
import * as types from './types';

const initialState = {
  status: 'init',
  errors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CONTACT_CREATED: {
      const success = _.get(action, 'payload.data.submission', false);
      const errors = _.get(action, 'payload.data.errors', []);

      if (success) {
        return {
          status: 'sent',
          errors: [],
        };
      }

      return {
        status: 'error',
        errors,
      };
    }
    default: {
      return state;
    }
  }
};
