import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ImageTextCard = ({
  text, image, imageAlt, link, linkText,
}) => {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 300);

  return (
    <div className="col-md-4" style={{ display: 'flex' }}>
      <div className="paginaBlokItem">
        <Link to={link} className="shadow-sm animeren zelfdeHoogte">
          <img src={image} className="img-fluid" alt={imageAlt} />
          <div className="p-3">
            <div className="tekst zelfdeHoogte2">{text}</div>
            <h3>{linkText.toUpperCase()}</h3>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ImageTextCard;

ImageTextCard.defaultProps = {
  imageAlt: '',
};

ImageTextCard.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};
