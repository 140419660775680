import axios from 'axios';
import * as types from './types';
import { GRAPHQL_QUERY_ENTRY } from '../models/query';

const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL;
const GRAPHQL_API_TOKEN = process.env.REACT_APP_GRAPHQL_API_TOKEN;

const fetchPage = (uri) => {
  const params = new URLSearchParams();
  params.append('query', GRAPHQL_QUERY_ENTRY);
  params.append('variables[uri]', uri);

  const request = axios.post(
    `${GRAPHQL_API_URL}`,
    params,
    {
      dataType: 'json',
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${GRAPHQL_API_TOKEN}`,
      },
    },
  );

  return {
    type: types.PAGE_FETCHED,
    payload: request,
  };
};

export default fetchPage;
