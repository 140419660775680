import { combineReducers } from 'redux';

import participation from './participation/reducers';
import navigation from './navigation/reducers';
import contact from './contact/reducers';
import page from './page/reducers';

const rootReducer = combineReducers({
  participation,
  navigation,
  contact,
  page,
});

export default rootReducer;
