import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import ImageTextCard from '../ImageTextCard';

const ImageTextCardList = ({ cards }) => {
  const cardList = (cards || []).map((card) => (
    <ImageTextCard
      key={`${card.uid}${_.random(0, 200, false)}`}
      text={card.text}
      image={card.images[0].url_small}
      imageAlt={_.get(card, 'imageAlt', '')}
      link={`/${card.uri}`}
      linkText={card.title}
    />
  ));

  const loader = (
    <div className="col-12 text-center">
      <Loader
        type="Grid"
        color="#EEEEEE"
        height={150}
        width={100}
      />
    </div>
  );

  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="row">
            {cardList.length ? cardList : loader}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ImageTextCardList;

ImageTextCardList.defaultProps = {
  cards: [],
};

ImageTextCardList.propTypes = {
  cards: PropTypes.oneOfType([PropTypes.array]),
};
